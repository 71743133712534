<template>
  <div class="login-frame">
    <div class="header">
      <router-link to="/" class="btn-go-home">
        <img src="@/assets/images/IA/icon_home_w.png" alt="icon">
        <p>홈으로 이동</p>
      </router-link>
    </div>
    <div class="main-container">
      <img src="@/assets/images/IA/img_primary_logo.png" alt="logo">
      <div class="login-form">
        <form>
          <h1>치료사 로그인</h1>
          <p class="sign-up">
            아직 계정이 없으신가요?
            <router-link to="/therapist/signUp">
              <strong>회원가입</strong>
            </router-link>
          </p>
          <div class="label-box">
            <label>
              아이디
            </label>
            <input type="text" placeholder="이메일 아이디 입력" class="custom-input-box" v-model="email">
          </div>
          <div class="label-box">
            <label>
              패스워드
            </label>
            <input type="password" placeholder="패스워드 입력" class="custom-input-box" v-model="password" autoComplete="off"
                   v-on:keypress.enter.prevent="getLogin">
          </div>
          <h4 class="text-right">
            <router-link to="/therapist/findPassword" class="btn-find">
              비밀번호 찾기
            </router-link>
            I
            <router-link to="/therapist/findId" class="btn-find">
              아이디 찾기
            </router-link>
          </h4>
        </form>
        <button class="custom-btn-box" @click="getLogin">로그인</button>
      </div>
    </div>
    <Progress :isProgress="isProgress"/>
  </div>
</template>

<script>

import {firebase} from "@/firebase/firebaseConfig";
import {firebaseError} from "@/lib/firebaseError";
import Progress from "@/components/Progress";

export default {
  name: "TherapistLoginPage",
  components: {Progress},
  data() {
    return {
      fbCollection: 'therapists',
      email: '',
      password: '',
      isProgress: false,
    }
  },
  methods: {
    getLogin() {
      const self = this;
      if (self.email.trim().length === 0) {
        alert('이메일을 입력하세요.');
        return
      } else if (self.password.trim().length === 0) {
        alert('비밀번호를 입력하세요.');
        return
      }
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      self.isProgress = true;
      defRef.where('email', '==', self.email)
          .get()
          .then((querySnapshot) => {
            if (querySnapshot.size !== 0) {
              self.login()
            } else {
              self.isProgress = false;
              alert('등록된 아이디가 없습니다.');
              self.email = '';
            }
          });
    },
    checkLoginAllowed() {
      const logoutTime = localStorage.getItem('logoutTime');
      if (logoutTime) {
        const now = new Date().getTime();
        const elapsedSeconds = (now - parseInt(logoutTime)) / 200; // parseInt() 함수를 사용하여 숫자로 변환
        if (elapsedSeconds < 10) {
          alert('이전 접속 기록을 처리 중입니다. 잠시후 접속해주세요.');
          return false;
        }
      }
      return true;
    },
    login() {
      const self = this;
      if (!self.checkLoginAllowed()) {
        self.isProgress = false;
        location.reload()
        return
      }
      const id = self.email;
      const password = self.password;

      firebase.auth().signInWithEmailAndPassword(id, password).then(async (result) => {
        // console.log(result.user)
        await self.setLog(result.user.uid)
      }).catch((error) => {
        // 실패 후 실행할 코드
        self.isProgress = false;
        firebaseError(error);
      })
    },
    async setLog(uid) {
      const self = this;
      const db = firebase.firestore();
      const defRef = db.collection(self.fbCollection);
      await defRef.doc(uid)
          .update({lastLog: firebase.firestore.Timestamp.fromDate(new Date())});
      self.isProgress = false;
      await self.$router.replace('/therapist/patientsManage');
    },


  }
}
</script>

<style scoped>

.custom-input-box {
  font-size: 16px;
}

.sign-up {
  font-size: 16px;
  font-weight: 400;
  color: #8D8D8D;
  margin-bottom: 38px;
}

strong {
  margin-left: 13px;
}

.login-frame {
  background-color: #0069CA;
}

.header {
  height: 80px;
  display: flex;
  flex-direction: row;
}

.btn-go-home {
  display: flex;
  margin-left: auto;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.btn-go-home img {
  width: 20px;
  height: 20px;
}

.btn-go-home p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 36px;
  letter-spacing: 0.0125em;
  color: #FFFFFF;
  margin: 0 15px 0 5px;
}

.main-container {
  height: 688px;
  text-align: center;
}


.main-container img {
  width: 193px;
  height: 41px;
  margin-top: 64px;
  margin-bottom: 30px;
}

.login-form {
  margin: 0 auto;
  width: 418px;
  height: 480px;
  background: #FFFFFF;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 40px 20px;
}

.login-form h1 {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  margin-bottom: 20px;
}

.login-form h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
  margin-bottom: 40px;
}

.login-form h4 strong {
  color: #0069CA;
  cursor: pointer;
  margin-left: 8px;
}

.login-form label {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #272833;
  margin-bottom: 10px;
  text-align: left !important;
}

.label-box {
  text-align: left;
  margin-bottom: 20px;
}

.btn-find {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.001em;
  color: #999999;
}

.btn-find:first-child {
  margin-right: 3px;
}

.btn-find:last-child {
  margin-left: 3px;
}
</style>
